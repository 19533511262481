/**
 * @Description:
 * @Author: chenz-l
 * @Date: 2024-05-07 15:05:01
 * @LastEditors: chenz-l
 */
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import { message } from 'antd';

/**
 * @description: 深度克隆
 * @return {*}
 */

export function CloneValueDeep<T>(value: T): T {
  return cloneDeep(value);
}

/**
 * ## 金额会计符
 * @param {number} val
 * @param {number} lastLength 保留几位小数
 * @returns
 */
export const moneyFormat = (val, lastLength = 2) => {
  if (val === '') {
    return val;
  }
  const tag = (val || 0).toString().split('.');
  tag[0] = tag[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  lastLength > 0 && (tag[1] = (tag[1] || '').padEnd(lastLength, '0'));
  return tag.join('.');
};

/**
 * ## 设置窗口宽高
 * @param {number} otherHeight 其他部分高度
 * @param {func} setHeight 设置高度函数
 * @param {number} otherWidth 其他部分宽度
 * @param {func} setWidth 设置宽度函数
 * @returns
 */
export const setWindowWidthHeight = (otherHeight, setHeight, otherWidth = 0, setWidth?) => {
  const otherElementsHeight = otherHeight;
  const otherElementsWidth = otherWidth;
  const viewportHeight = window.innerHeight;
  const viewportWidth = window.innerWidth;
  const availableHeight = viewportHeight - otherElementsHeight;
  const availableWidth = viewportWidth - otherElementsWidth;
  setHeight(availableHeight);
  setWidth && setWidth(availableWidth);
  // 添加窗口大小改变事件监听器
  window.addEventListener('resize', handleResize);
  return () => {
    // 组件卸载时移除事件监听器
    window.removeEventListener('resize', handleResize);
  };
  // 处理窗口大小改变事件
  function handleResize() {
    const newAvailableHeight = window.innerHeight - otherElementsHeight;
    const newAvailableWidth = window.innerWidth - otherElementsWidth;
    setHeight(newAvailableHeight);
    setWidth && setWidth(newAvailableWidth);
  }
};

/**
 * @description 解析url中的param
 * @param {string} url 地址路径
 */
export const parseUrl = (url: string) => {
  const result = {};
  if (!url) return {};
  const query = url.split('?')[1];
  const queryArr = query && query.length ? query.split('&') : [];
  queryArr.forEach(item => {
    const value = item.split('=')[1];
    const key = item.split('=')[0];
    result[key] = value;
  });
  return result;
};

/**
 * @description 下拉框数据格式化
 * @param
 */
export const selectOptionsFormat = options => {
  const newOptions = options.map((item: { name?: string; value?: string; code?: string }) => {
    return { label: item.name, value: item.value || item.code };
  });
  return newOptions;
};

/**
 * @description 统计分析一级页面数据格式转换
 * @param
 */
export const statisticalAnalysisFormat = (data, regionList) => {
  if (!data) {
    return;
  }
  const metaConfig = () => {
    // 数值类型无其他操作（直接展示数值）
    // 保留两位小数
    // 加千分符
    // 转百分比

    // 字符串类型直接展示

    // 分子分母格式类型（相除）：聚合时用所有分子加和除以所有分母加和
    // 转百分比
    // 保留两位小数

    // 日期类型直接展示
    return data.headers?.map(headerItem => {
      if (headerItem.col_type === 'TYPE_STRING' || headerItem.col_type === 'TYPE_DATE') {
        // 字符串 枚举 日期直接展示
        return {
          field: headerItem.col_name,
          name: headerItem.display_name,
          formatter: (value, record, meta) => {
            if (value === null || value === '') {
              return '--';
            }
            if (headerItem?.suffix) {
              value = value + headerItem?.suffix;
            }
            return value;
          },
        };
      } else if (headerItem.col_type === 'TYPE_ENUM') {
        return {
          field: headerItem.col_name,
          name: headerItem.display_name,
          formatter: (value, record, meta) => {
            const region_item = regionList[headerItem?.enum_format?.cole_enum_type]?.filter(
              item => {
                return item.code === value;
              }
            );
            let newValue = region_item && region_item[0]?.name;
            if (headerItem?.suffix) {
              newValue = newValue + headerItem?.suffix;
            }
            return newValue;
          },
        };
      } else if (headerItem.col_type === 'TYPE_NUMBER') {
        // 数值类型
        if (headerItem.number_format) {
          return {
            field: headerItem.col_name,
            name: headerItem.display_name,
            formatter: (value, record, meta) => {
              if (value === null || value === '' || value === 0) {
                return headerItem?.suffix ? 0 + headerItem?.suffix : 0;
              }
              let newValue = value;
              // 保留两位小数不转百分比
              if (
                headerItem.number_format.decimal_places !== undefined &&
                (headerItem?.number_format.is_percentage === undefined ||
                  !headerItem?.number_format.is_percentage)
              ) {
                newValue = newValue?.toFixed(headerItem.number_format.decimal_places);
              }
              // 千分符
              if (headerItem.number_format.user_thousand_separator) {
                newValue = newValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                // newValue = Number(newValue).toLocaleString();
              }
              // 保留两位小数转百分比
              if (
                headerItem.number_format.decimal_places !== undefined &&
                headerItem?.number_format.is_percentage
              ) {
                newValue = (newValue * 100).toFixed(headerItem.number_format.decimal_places) + '%';
              }
              // 不保留两位小数转百分比
              if (
                headerItem.number_format.decimal_places === undefined &&
                headerItem?.number_format.is_percentage
              ) {
                newValue = newValue * 100 + '%';
              }
              if (headerItem?.suffix) {
                newValue = newValue + headerItem?.suffix;
              }
              return newValue;
            },
          };
        } else {
          return {
            field: headerItem.col_name,
            name: headerItem.display_name,
            formatter: (value, record, meta) => {
              if (value === null || value === '') {
                return headerItem?.suffix ? 0 + headerItem?.suffix : 0;
              }
              if (headerItem?.suffix) {
                return value + headerItem?.suffix;
              }
              return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            },
          };
        }
      } else if (headerItem.col_type === 'TYPE_DIVIDE') {
        // 相除类型
        if (headerItem.divide_format) {
          return {
            field: headerItem.col_name,
            name: headerItem.display_name,
            formatter: (value, record, meta) => {
              if (value === null || value === '' || value === '||') {
                return 0;
              }
              const numeratorDenominator = String(value)?.split(' || ');
              const teansformValue =
                Number(numeratorDenominator[0]) === 0
                  ? 0
                  : Number(numeratorDenominator[0]) / Number(numeratorDenominator[1]);
              let newValue: any = teansformValue;
              // 保留两位小数
              if (
                headerItem.divide_format.decimal_places !== undefined &&
                !headerItem.divide_format.is_percentage
              ) {
                newValue = newValue.toFixed(Number(headerItem.divide_format.decimal_places));
              }
              // 转百分比
              if (
                headerItem.divide_format.decimal_places &&
                headerItem.divide_format.is_percentage
              ) {
                newValue =
                  (newValue * 100).toFixed(Number(headerItem.divide_format.decimal_places)) + '%';
              }
              if (headerItem?.suffix) {
                newValue = newValue + headerItem?.suffix;
              }
              return newValue;
            },
          };
        } else {
          return {
            field: headerItem.col_name,
            name: headerItem.display_name,
            formatter: (value, record, meta) => {
              if (value === null || value === '' || value === '||') {
                return headerItem?.suffix ? 0 + headerItem?.suffix : 0;
              }
              if (headerItem?.suffix) {
                value = value + headerItem?.suffix;
              }
              return value;
            },
          };
        }
      } else {
        return {
          field: headerItem.col_name,
          name: headerItem.display_name,
          formatter: value => {
            if (value === null || value === '') {
              return 0;
            }
            if (headerItem?.suffix) {
              value = value + headerItem?.suffix;
            }
            return value;
          },
        };
      }
    });
  };

  const colNameList = data.headers
    ?.filter(item => !data.merged_headers?.includes(item.display_name))
    .map(i => {
      return i.col_name;
    });
  data.data.forEach(item => {
    const itemKeys = Object.keys(item);
    colNameList.forEach(i => {
      if (!itemKeys?.includes(i)) {
        item[i] = null;
      }
    });
  });

  const newData = {
    ...data,
    data: data.data,
    fields: {
      rows: data.headers
        .filter(item => {
          if (data.merged_headers?.includes(item.display_name)) {
            return item.col_name;
          }
        })
        .map(i => {
          return i.col_name;
        }),
      values: data.merged_headers ? colNameList : data.headers?.map(item => item.col_name),
      valueInCols: true,
    },
    meta: metaConfig(),
  };

  return newData;
};
/**
 * @description 统计分析二级页面数据格式转换
 * @param
 */
export const secondaryFormat = (data, isOperation: boolean) => {
  if (!data) {
    return;
  }

  const metaConfig = () => {
    return data.headers?.map(headerItem => {
      if (headerItem.col_type === 'TYPE_STRING' || headerItem.col_type === 'TYPE_DATE') {
        // 字符串 枚举 日期直接展示
        return {
          field: headerItem?.col_name,
          name: headerItem?.display_name,
          formatter: value => {
            if (value === null || value === '') {
              return '--';
            }
            if (headerItem?.suffix) {
              value = value + headerItem?.suffix;
            }
            return value;
          },
        };
      } else if (headerItem?.col_type === 'TYPE_ENUM') {
        return {
          field: headerItem?.col_name,
          name: headerItem?.display_name,
          formatter: value => {
            if (value === null || value === '') {
              return '--';
            }
            if (headerItem?.suffix) {
              value = value + headerItem?.suffix;
            }
            return value;
          },
        };
      } else if (headerItem?.col_type === 'TYPE_NUMBER') {
        // 数值类型
        if (headerItem?.number_format) {
          return {
            field: headerItem?.col_name,
            name: headerItem?.display_name,
            formatter: (value, record, meta) => {
              if (value === null || value === 0) {
                if (headerItem?.suffix) {
                  return 0 + headerItem?.suffix;
                } else if (headerItem?.number_format?.is_percentage) {
                  return '0.00%';
                } else {
                  return 0;
                }
              }
              let newValue = value;
              // 保留两位小数不转百分比
              if (
                headerItem.number_format.decimal_places !== undefined &&
                (headerItem?.number_format.is_percentage === undefined ||
                  !headerItem?.number_format.is_percentage)
              ) {
                newValue = Number(newValue).toFixed(headerItem.number_format.decimal_places);
              }
              // 千分符
              if (headerItem?.number_format?.user_thousand_separator) {
                newValue = String(newValue)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                // newValue = newValue.toLocaleString();
              }
              // 保留两位小数转百分比
              if (
                headerItem.number_format.decimal_places !== undefined &&
                headerItem?.number_format.is_percentage
              ) {
                newValue = (newValue * 100).toFixed(headerItem.number_format.decimal_places) + '%';
              }
              // 不保留两位小数转百分比
              if (
                headerItem.number_format.decimal_places === undefined &&
                headerItem?.number_format.is_percentage
              ) {
                newValue = newValue * 100 + '%';
              }
              if (headerItem?.suffix) {
                newValue = newValue + headerItem?.suffix;
              }
              return newValue;
            },
          };
        } else {
          return {
            field: headerItem?.col_name,
            name: headerItem?.display_name,
            formatter: (value, record, meta) => {
              if (value === null || value === 0) {
                if (
                  value === null &&
                  [
                    '客户满意度',
                    '保险公司满意度',
                    '总体满意度',
                    '客户服务评价',
                    '保险公司服务评价',
                    '客户评分',
                    '保险机构评分',
                  ].includes(headerItem?.col_name)
                ) {
                  return '--';
                } else {
                  if (headerItem?.suffix) {
                    return 0 + headerItem?.suffix;
                  } else if (headerItem?.number_format?.is_percentage) {
                    return '0.00%';
                  } else {
                    return 0;
                  }
                }
              }
              if (headerItem?.suffix) {
                return value + headerItem?.suffix;
              }
              return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            },
          };
        }
      } else if (headerItem?.col_type === 'TYPE_DIVIDE') {
        // 相除类型
        if (headerItem?.divide_format) {
          return {
            field: headerItem?.col_name,
            name: headerItem?.display_name,
            formatter: (value, record, meta) => {
              if (value === null || value === 0 || value === '||') {
                if (headerItem?.suffix) {
                  return 0 + headerItem?.suffix;
                } else if (headerItem?.number_format?.is_percentage) {
                  return '0.00%';
                } else {
                  return 0;
                }
              }
              const numeratorDenominator = String(value)?.split(' || ');
              const teansformValue =
                Number(numeratorDenominator[0]) === 0
                  ? 0
                  : Number(numeratorDenominator[0]) / Number(numeratorDenominator[1]);
              let newValue: any = teansformValue;
              // 保留两位小数
              if (
                headerItem?.divide_format?.decimal_places &&
                !headerItem?.divide_format?.is_percentage
              ) {
                newValue = newValue.toFixed(Number(headerItem?.divide_format?.decimal_places));
              }
              // 转百分比
              if (
                headerItem?.divide_format?.decimal_places &&
                headerItem?.divide_format?.is_percentage
              ) {
                newValue =
                  (newValue * 100).toFixed(Number(headerItem?.divide_format?.decimal_places)) + '%';
              }
              if (headerItem?.suffix) {
                newValue = newValue + headerItem?.suffix;
              }
              return newValue;
            },
          };
        } else {
          return {
            field: headerItem?.col_name,
            name: headerItem?.display_name,
            formatter: value => {
              if (value === null || value === 0 || value === '||') {
                if (headerItem?.suffix) {
                  return 0 + headerItem?.suffix;
                } else if (headerItem?.number_format?.is_percentage) {
                  return '0.00%';
                } else {
                  return 0;
                }
              }
              if (headerItem?.suffix) {
                value = value + headerItem?.suffix;
              }
              return value;
            },
          };
        }
      } else {
        return {
          field: headerItem?.col_name,
          name: headerItem?.display_name,
          formatter: (value, record, meta) => {
            if (value === null || value === 0) {
              if (headerItem?.suffix) {
                return 0 + headerItem?.suffix;
              } else if (headerItem?.number_format?.is_percentage) {
                return '0.00%';
              } else {
                return 0;
              }
            }
            if (headerItem?.suffix) {
              value = value + headerItem?.suffix;
            }
            return value;
          },
        };
      }
    });
  };
  const colNameList = data.headers?.map(i => {
    return i.col_name;
  });
  data.data.records.forEach((item, key: number) => {
    const itemKeys = Object.keys(item);
    colNameList.forEach(i => {
      if (!itemKeys?.includes(i)) {
        item[i] = null;
      }
    });
    item.操作 = key;
  });

  const dataColumns = data.headers?.map(item => {
    if (!item.col_name) {
      return '';
    }
    return item?.col_name;
  });

  const newData = {
    ...data,
    fields: isOperation ? { columns: [...dataColumns, '操作'] } : { columns: dataColumns },
    data: data.data.records,
    meta: isOperation
      ? [...metaConfig(), { field: '操作', name: '操作', formatter: () => '查看详情' }]
      : metaConfig(),
  };

  return newData;
};

/**
 * @description: 使用时必须于请求头加上responseType: 'blob'
 * @stream 文件流
 * @filename 文件名称
 * @suffix 后缀（文件导出格式）
 */
export const downloadFile = (
  stream,
  filename: string = moment(new Date()).format('YYYYMMDD'), //不传值默认以当前时间为文件名
  suffix: string
) => {
  //通过new Blob和文件格式生成blob对象
  const blob = new Blob([stream]);
  const objectURL = URL.createObjectURL(blob);
  let link: any = document.createElement('a');
  //下载的文件名
  link.download = `${filename}${suffix}`;
  link.href = objectURL;
  link.click();
  URL.revokeObjectURL(objectURL);
  message.success('下载成功');
  link = null;
};

/**
 * @description: 使用url直接下载文件
 * @url 文件地址
 * @fileName 文件名称
 * @suffix 后缀（文件导出格式）
 */
export const urlDownload = url => {
  // 创建隐藏的可下载链接
  const eleLink = document.createElement('a');
  // eleLink.download = fileName + suffix;
  eleLink.style.display = 'none';
  eleLink.href = url;
  // 触发点击
  document.body.appendChild(eleLink);
  eleLink.click();
  message.success('下载成功');
  // 然后移除
  document.body.removeChild(eleLink);
};

/**
 * @description: 金额转换
 * @value 初始值
 * @suffix 后缀
 */

export const transformMoney = (value: number | string, suffix?: string, decimals?: number) => {
  const newValue =
    decimals || decimals === 0
      ? Number(value)
          .toFixed(decimals)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      : value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return suffix ? suffix + newValue : newValue;
};

/**
 * @description: 小数转百分比
 * @value 初始值
 * @suffix 后缀
 * @decimals 保留小数位数
 */

export const decimaltoPercentage = (value: number | string, suffix?: string, decimals?: number) => {
  if (!value) {
    return '--';
  }
  const newValue =
    decimals || decimals === 0 ? (Number(value) * 100).toFixed(Number(decimals)) : Number(value);
  return suffix ? newValue + suffix : newValue;
};

/**
 * ## 复制到剪切板
 * @param {*} content 要复制的内容
 */
export const copyToClipboard = content => {
  const tempInput = document.createElement('input');
  tempInput.value = content;
  tempInput.readOnly = true;
  document.body.appendChild(tempInput);
  tempInput.select();
  document.execCommand('copy');
  document.body.removeChild(tempInput);
};
/**
 * ## 判断当前浏览器是否是Edge
 * @returns true 是Edge浏览器
 */
export const isIeOrEdge = () => {
  const { userAgent } = window.navigator;
  return /Edg|Edge/.test(userAgent);
};

/**
 * @description 树形数据格式化
 * @param
 */
export const treeOptionsFormat = options => {
  console.log(options);

  const newOptions = options.map((item: { name?: string; value?: string; code?: string }) => {
    return { label: item.name, value: item.value || item.code };
  });
  return newOptions;
};
